.subCategories {
    display: flex;
    justify-content: space-around;
}

.activeSubCategory {
    position: relative;
    border-radius: 2rem;
    padding: 0.5rem 1rem;
    background-color: var(--primary-color);
    white-space: nowrap;
}

    .activeSubCategory div {
        position: absolute;
        left: 42%;
        width: 15px;
        height: 15px;
        transform: rotate(45deg);
        background-color: var(--primary-color);
    }

.subCategory-selection select {
    border: 2px solid var(--primary-color);
    border-radius: 0.5rem;
    padding: 0.5rem;
    outline: none;
}
.loader {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

    .loader img {
        animation: 1s linear infinite load;
    }

@keyframes load {
    to {
        transform: rotate(360deg);
    }
}

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.username {
    display: none;
}

.login-link .link {
    display: flex;
    align-items: center;
}

.logout-button {
    background: transparent;
    border: none;
}

.login-link .link:hover {
    color: var(--primary-color);
}

.login-link .link:hover #Login path {
    stroke: var(--primary-color);
}

.login-link .link:hover #Logout {
    fill: var(--primary-color);
}

@media(min-width: 800px) {
    header {
        flex-direction: column;
        justify-content: flex-start;
        align-items: start;
        padding: 2rem 1rem;
        background-color: rgb(56, 56, 56);
    }

    .username {
        display: initial;
    }

    .login-link {
        margin-top: auto;
    }
}